import { createApp } from 'vue'

import Bildirim from '../KNET_resources/vue/bildirimler/bildirim.vue'

import Toast, { PluginOptions } from "vue-toastification";


/* import VMultiselect from '../vue/vmultiselect.vue' */

const options: PluginOptions = {
    // You can set your default options here
};


const app = createApp({})
        .use(Toast, options)
        .component("bildirim", Bildirim)
        .mount('#vue');

