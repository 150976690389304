

	import { defineComponent, reactive, toRefs, ref, PropType } from 'vue';
	import IBildirim from "./types/bildirim";
	import Axios from 'axios';
	import Echo from 'laravel-echo';
	import Pusher from 'pusher-js';
	import { useToast, POSITION, TYPE } from "vue-toastification";

	export default defineComponent({
		name : "Bildirim",

		mounted() {
			this.bindChannels();
		},

		props : {
			guid : {required : true,
			type : String },
		},

		setup(props, context) {
			let bildirim = {} as IBildirim;
			const guid = props.guid;
			const toast = useToast();

			let echo = new Echo({	broadcaster: 'pusher',
									key: "1.3.6.1.4.1.54347.1.1",
									wsPort: '6002',
									wssPort: '6002',
									httpHost: 'sms.gov.ct.tr',
  									wsHost: 'sms.gov.ct.tr',
  									wssHost: 'sms.gov.ct.tr',
									enabledTransports: ['ws', 'wss'],
									//forceTLS: false,
									encrypted: true,
									disableStats: true,
									//authEndpoint: 'https://hesabim.gov.ct.tr/broadcasting/auth',
									namespace : "App.Events.KNET_events" });

			Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

			return {  guid, bildirim , echo, toast }; 
		},


		methods: {

			bindChannels(){

				this.echo
					.private('kullanici.' + this.guid)
					.listen('WebSocketBildirimGonderEvent',
							(e : IBildirim) => {

								this.bildirim.baslik = e.baslik;
								this.bildirim.mesaj = e.mesaj;
								this.bildirim.tip = e.tip;
								this.bildirim.ikon = e.ikon;

								if ( Object.keys(e).includes("tarih") )
									this.bildirim.tarih = e.tarih;
								else
									this.bildirim.tarih = (new Date().toLocaleTimeString());

								if ( Object.keys(e).includes("id") )
									this.bildirim.id = e.id;

								if ( Object.keys(e).includes("ikon") )
									this.bildirim.ikon = e.ikon + " fa-2x";
								else
									this.bildirim.ikon = "fa fa-info-circle fa-2x";


								Axios	.get(	`/bildirimOkundu`,
												{ params : { id: e.id}})
										.catch(e => { console.log("Hata oluştu:" , e); });

								console.log(e);

								this.bildirimGoster(); });
			},

			bildirimGoster () {
				let tip : TYPE;
				switch (this.bildirim.tip) {
					case "success":
						tip = TYPE.SUCCESS; 
						break;
					case "warning":
						tip = TYPE.WARNING;
						break;
					case "error":
						tip = TYPE.ERROR; 
						break;
					default:
						tip = TYPE.DEFAULT;
				}

				this.toast(	this.bildirim.mesaj, 
							{ 	timeout: this.bildirim.onayli ? false : 5000,
								position: POSITION.BOTTOM_RIGHT,
								type: tip,
								closeOnClick: true,
								pauseOnFocusLoss: false,
								pauseOnHover: true,
								draggable: false,
								draggablePercent: 1.72,
								showCloseButtonOnHover: true,
								hideProgressBar: false,
								closeButton: "button",
								icon: this.bildirim.ikon,
								rtl: false })
			}
		}
	});

